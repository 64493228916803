<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" lg="6" md="6">
            <span class="green--text">Sub tipo evento</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="SubEventType"
              :error-messages="validationErrors.event_subtype_id"
              item-text="name"
              item-value="id"
              v-model="eventTypeRequires.event_subtype_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Nombre</span>
            <v-text-field
              v-model="eventTypeRequires.name"
              :error-messages="validationErrors.name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Tipo campo</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="TypeText"
              :error-messages="validationErrors.type"
              item-text="name"
              item-value="id"
              v-model="eventTypeRequires.type"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Fuente datos</span>
            <v-text-field
              v-model="eventTypeRequires.data_source"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import {
  storeEventTypeRequires,
  updateEventTypeRequires,
  eventSubTypeEvents,
} from "@/services/humansRights";
import sweetalert from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
  },
  name: "newEventTypeRequires.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      territoryTypes: [],
      SubEventType: [],
      TypeText: [
        {
          id: "TEXT",
          name: "TEXT",
        },
        {
          id: "OPTIONS",
          name: "OPTIONS",
        },
        {
          id: "NUMBER",
          name: "NUMBER",
        },
        {
          id: "DATE",
          name: "DATE",
        },
      ],
      eventTypeRequires: {
        id: "",
        name: "",
        event_subtype_id: "",
        data_source: "",
        type: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      eventTypeRequires: {
        name: { required },
        event_subtype_id: { required },
        type: { required },
      },
    };
  },
  methods: {
    addAffected(affected) {
      this.eventTypeRequires.affecteds.push(affected);
    },
    removeAffected(affected) {
      const index = this.eventTypeRequires.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.eventTypeRequires.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.eventTypeRequires.affecteds.splice(index, 1);
    },
    async save() {
      this.$v.eventTypeRequires.$touch();
      if (this.$v.eventTypeRequires.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.eventTypeRequires.name,
          event_subtype_id: this.eventTypeRequires.event_subtype_id,
          data_source: this.eventTypeRequires.data_source,
          type: this.eventTypeRequires.type,
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateEventTypeRequires(
                this.eventTypeRequires.id,
                eventRequestData
              )
            : await storeEventTypeRequires(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire(
          "Registro guardado correctamente",
          "",
          "success"
        );

        this.hideLoader();

        this.$router.push("/humansRights/list-type-requires");

      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
            "Error",
            "ha ocurrido un error al procesar la solicitud",
            "error"
        );
      }
    },
    setEventData(eventData) {
      this.eventTypeRequires = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {
    const eventTypesResponse = await eventSubTypeEvents();
    this.SubEventType = eventTypesResponse.data;
  },
  computed: {
    validationErrors() {
      return {
        name: [
          ...[
            !this.$v.eventTypeRequires.name.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        event_subtype_id: [
          ...[
            !this.$v.eventTypeRequires.event_subtype_id.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        type: [
          ...[
            !this.$v.eventTypeRequires.type.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
};
</script>

<style scoped></style>
